import {
  FRAME_COLORS,
  TILE_SIZES,
  TILE_SIZES_ROTATION,
} from '@mixtiles/web-backend-shared'
import StorageManager from '../../services/StorageManager'
import { MIXED_SIZES } from '../../pages/PhotoStyler/TileDesignerConsts'
import type { TILES_SOURCE } from '../../components/TilesProvider/TilesProvider.consts'
import { getStorageKeyBySource } from '../../components/TilesProvider/TilesSourceContext'
import { guardIsEnum } from '../../utils/typeUtils'

export const SELECTED_SIZE_KEY = 'SelectedSize'
export const SELECTED_COLOR_KEY = 'SelectedColor'

export function setSelectedColor(
  color: string | null,
  tilesSource: TILES_SOURCE
) {
  if (color && guardIsEnum(color, FRAME_COLORS)) {
    StorageManager.set(
      getStorageKeyBySource(SELECTED_COLOR_KEY, tilesSource),
      color
    )
  }
}

export function setSelectedSize(
  size: string | null,
  tilesSource: TILES_SOURCE
) {
  if (!size) {
    return
  }

  const upperCaseSize = size.toUpperCase()
  if (size === MIXED_SIZES) {
    StorageManager.set(
      getStorageKeyBySource(SELECTED_SIZE_KEY, tilesSource),
      MIXED_SIZES
    )
  } else if (guardIsEnum(upperCaseSize, TILE_SIZES)) {
    StorageManager.set(
      getStorageKeyBySource(SELECTED_SIZE_KEY, tilesSource),
      upperCaseSize
    )
  }
}

export function isRotated(size1: TILE_SIZES, size2: TILE_SIZES) {
  return size1 === TILE_SIZES_ROTATION[size2]
}

export function getDefaultTileSize(): TILE_SIZES {
  return TILE_SIZES.SQUARE_8X8
}

export function getDefaultArtTileSize(isDefault20X27: boolean): TILE_SIZES {
  return isDefault20X27
    ? TILE_SIZES.RECTANGLE_20X27
    : TILE_SIZES.RECTANGLE_12X16
}
