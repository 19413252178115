import { createIcon } from './Icon'
import { ReactComponent as ArtSVG } from './assets/Art.svg'
import { ReactComponent as ArrangeSVG } from './assets/Arrange.svg'
import { ReactComponent as BrushSVG } from './assets/Brush.svg'
import { ReactComponent as CameraAngleSVG } from './assets/CameraAngle.svg'
import { ReactComponent as CartSVG } from './assets/Cart.svg'
import { ReactComponent as ChatSVG } from './assets/Chat.svg'
import { ReactComponent as CheckSVG } from './assets/Check.svg'
import { ReactComponent as CheckButtonSVG } from './assets/CheckButton.svg'
import { ReactComponent as ChevronDownSVG } from './assets/ChevronDown.svg'
import { ReactComponent as ChevronDownGreyDarkSVG } from './assets/ChevronDownGreyDark.svg'
import { ReactComponent as ColorsSVG } from './assets/Colors.svg'
import { ReactComponent as CropSVG } from './assets/Crop.svg'
import { ReactComponent as EditSVG } from './assets/Edit.svg'
import { ReactComponent as StoreSVG } from './assets/Store.svg'
import { ReactComponent as PickupCloseButtonSVG } from './assets/pickupCloseButton.svg'
import { ReactComponent as EnterArrowSVG } from './assets/EnterArrow.svg'
import { ReactComponent as ExclamationMarkFillSVG } from './assets/ExclamationMarkFill.svg'
import { ReactComponent as ExclamationMarkSVG } from './assets/ExclamationMark.svg'
import { ReactComponent as FeatherSVG } from './assets/Feather.svg'
import { ReactComponent as FrameSVG } from './assets/Frame.svg'
import { ReactComponent as FrameCornerSVG } from './assets/FrameCorner.svg'
import { ReactComponent as GallerySVG } from './assets/Gallery.svg'
import { ReactComponent as GalleryWallsSVG } from './assets/GalleryWalls.svg'
import { ReactComponent as BookSVG } from './assets/Book.svg'
import { ReactComponent as GarbageSVG } from './assets/Garbage.svg'
import { ReactComponent as GiftSVG } from './assets/Gift.svg'
import { ReactComponent as HeartSVG } from './assets/Heart.svg'
import { ReactComponent as HeartFilledSVG } from './assets/HeartFilled.svg'
import { ReactComponent as HistorySVG } from './assets/History.svg'
import { ReactComponent as HoneycombSVG } from './assets/HoneycombShape.svg'
import { ReactComponent as LandscapeSVG } from './assets/LandscapeShape.svg'
import { ReactComponent as MaterialSVG } from './assets/Material.svg'
import { ReactComponent as MinimizeSVG } from './assets/Minimize.svg'
import { ReactComponent as NextArrowSVG } from './assets/NextArrowIcon.svg'
import { ReactComponent as NoneSVG } from './assets/None.svg'
import { ReactComponent as BackArrowSVG } from './assets/BackArrowIcon.svg'
import { ReactComponent as DownArrowSVG } from './assets/DownArrow.svg'
import { ReactComponent as UpArrowSVG } from './assets/UpArrow.svg'
import { ReactComponent as LeftArrowSVG } from './assets/LeftArrowIcon.svg'
import { ReactComponent as RightArrowSVG } from './assets/RightArrow.svg'
import { ReactComponent as LegacyLeftArrow } from './assets/LegacyLeftArrow.svg'
import { ReactComponent as MagnifyingGlassSVG } from './assets/MagnifyingGlass.svg'
import { ReactComponent as MinusSVG } from './assets/Minus.svg'
import { ReactComponent as MenuSVG } from './assets/Menu.svg'
import { ReactComponent as OptionsSVG } from './assets/Options.svg'
import { ReactComponent as PercentageMarkSVG } from './assets/PercentageMark.svg'
import { ReactComponent as PhotoSVG } from './assets/Photo.svg'
import { ReactComponent as PlusSVG } from './assets/Plus.svg'
import { ReactComponent as PortaritSVG } from './assets/PortraitShape.svg'
import { ReactComponent as PresentSVG } from './assets/Present.svg'
import { ReactComponent as QuestionMarkSVG } from './assets/QuestionMark.svg'
import { ReactComponent as ReplaceSVG } from './assets/Replace.svg'
import { ReactComponent as SizeSVG } from './assets/Size.svg'
import { ReactComponent as SubtractSVG } from './assets/Subtract.svg'
import { ReactComponent as SupportSVG } from './assets/Support.svg'
import { ReactComponent as SquareSVG } from './assets/SquareShape.svg'
import { ReactComponent as SwapSVG } from './assets/Swap.svg'
import { ReactComponent as TagPinkSVG } from './assets/TagPink.svg'
import { ReactComponent as TagSVG } from './assets/Tag.svg'
import { ReactComponent as TextSVG } from './assets/Text.svg'
import { ReactComponent as ThinBackArrowSVG } from './assets/ThinBackArrow.svg'
import { ReactComponent as XSVG } from './assets/X.svg'
import { ReactComponent as XNoPaddingSVG } from './assets/XNoPadding.svg'
import { ReactComponent as DisabledSVG } from './assets/Disabled.svg'
import { ReactComponent as PhotoTileSVG } from './assets/PhotoTile.svg'
import { ReactComponent as PhotosSVG } from './assets/photos.svg'
import { ReactComponent as CollageSVG } from './assets/Collage.svg'
import { ReactComponent as CollageLayoutGridSVG } from './assets/collageLayoutGrid.svg'
import { ReactComponent as CollageLayoutMixedSVG } from './assets/collageLayoutMixed.svg'
import { ReactComponent as CollageLayoutHeartSVG } from './assets/collageLayoutHeart.svg'
import { ReactComponent as CollageLayoutNotesSVG } from './assets/collageLayoutNotes.svg'
import { ReactComponent as CollageLayoutCenteredSVG } from './assets/collageLayoutCentered.svg'
import { ReactComponent as CameraButtonSVG } from './assets/CameraButton.svg'
import { ReactComponent as CameraPlusSVG } from './assets/CameraPlus.svg'
import { ReactComponent as TrashSVG } from './assets/trash.svg'
import { ReactComponent as ShuffleSVG } from './assets/shuffle.svg'
import { ReactComponent as ReferralSVG } from './assets/referral.svg'
import { ReactComponent as VSignSVG } from './assets/Vsign.svg'
import { ReactComponent as SuitcaseSVG } from './assets/Suitcase.svg'
import { ReactComponent as I } from './assets/i.svg'

/**
 * To add your icon, follow the complete guidelines here:
 * https://www.notion.so/mixtiles/Icons-Usage-Adding-New-Ones-Etc-e3c75af3ca1d48558ec99c4ead48b58a
 */
export const ArtIcon = createIcon(ArtSVG)
export const ShuffleIcon = createIcon(ShuffleSVG)
export const ArrangeIcon = createIcon(ArrangeSVG)
export const CartIcon = createIcon(CartSVG)
export const ChatIcon = createIcon(ChatSVG)
export const CheckIcon = createIcon(CheckSVG)
export const CheckButtonIcon = createIcon(CheckButtonSVG)
export const ChevronDownIcon = createIcon(ChevronDownSVG)
export const ChevronDownGreyDarkIcon = createIcon(ChevronDownGreyDarkSVG)
export const CropIcon = createIcon(CropSVG)
export const EditIcon = createIcon(EditSVG)
export const StoreIcon = createIcon(StoreSVG)
export const PickupCloseButtonIcon = createIcon(PickupCloseButtonSVG)
export const EnterArrow = createIcon(EnterArrowSVG)
export const ExclamationMarkFillIcon = createIcon(ExclamationMarkFillSVG)
export const ExclamationMarkIcon = createIcon(ExclamationMarkSVG)
export const FeatherIcon = createIcon(FeatherSVG)
export const FrameIcon = createIcon(FrameSVG)
export const FrameCornerIcon = createIcon(FrameCornerSVG)
export const MaterialIcon = createIcon(MaterialSVG)
export const MinimizeIcon = createIcon(MinimizeSVG)
export const GalleryIcon = createIcon(GallerySVG)
export const GalleryWallsIcon = createIcon(GalleryWallsSVG)
export const BookIcon = createIcon(BookSVG)
export const GarbageIcon = createIcon(GarbageSVG)
export const GiftIcon = createIcon(GiftSVG)
export const HeartIcon = createIcon(HeartSVG)
export const HoneycombIcon = createIcon(HoneycombSVG)
export const LandscapeIcon = createIcon(LandscapeSVG)
export const BrushIcon = createIcon(BrushSVG)
export const CameraAngleIcon = createIcon(CameraAngleSVG)
export const ColorsIcon = createIcon(ColorsSVG)
export const HeartFilledIcon = createIcon(HeartFilledSVG)
export const HistoryIcon = createIcon(HistorySVG)
export const NextArrowIcon = createIcon(NextArrowSVG)
export const NoneIcon = createIcon(NoneSVG)
export const BackArrowIcon = createIcon(BackArrowSVG)
export const DownArrowIcon = createIcon(DownArrowSVG)
export const UpArrowIcon = createIcon(UpArrowSVG)
export const LeftArrowIcon = createIcon(LeftArrowSVG)
export const RightArrowIcon = createIcon(RightArrowSVG)
// This icon should be replaced by LeftArrowIcon which is in the right icon size
export const LegacyLeftArrowIcon = createIcon(LegacyLeftArrow)
export const MagnifyingGlassIcon = createIcon(MagnifyingGlassSVG)
export const MenuIcon = createIcon(MenuSVG)
export const MinusIcon = createIcon(MinusSVG)
export const PercentageMarkIcon = createIcon(PercentageMarkSVG)
export const PhotoIcon = createIcon(PhotoSVG)
export const PlusIcon = createIcon(PlusSVG)
export const PortaritIcon = createIcon(PortaritSVG)
export const QuestionMarkIcon = createIcon(QuestionMarkSVG)
export const ReplaceIcon = createIcon(ReplaceSVG)
export const SizeIcon = createIcon(SizeSVG)
export const SubtractIcon = createIcon(SubtractSVG)
export const SupportIcon = createIcon(SupportSVG)
export const SquareIcon = createIcon(SquareSVG)
export const SwapIcon = createIcon(SwapSVG)
export const TagPinkIcon = createIcon(TagPinkSVG)
export const TagIcon = createIcon(TagSVG)
export const TextIcon = createIcon(TextSVG)
export const ThinBackArrowIcon = createIcon(ThinBackArrowSVG)
export const XIcon = createIcon(XSVG)
export const XNoPaddingIcon = createIcon(XNoPaddingSVG)
export const DisabledIcon = createIcon(DisabledSVG)
export const OptionsIcon = createIcon(OptionsSVG)
export const PhotoTileIcon = createIcon(PhotoTileSVG)
export const PresentIcon = createIcon(PresentSVG)
export const ReferralIcon = createIcon(ReferralSVG)
export const CollageIcon = createIcon(CollageSVG)
export const CollageLayoutGridIcon = createIcon(CollageLayoutGridSVG)
export const CollageLayoutMixedIcon = createIcon(CollageLayoutMixedSVG)
export const CollageLayoutHeartIcon = createIcon(CollageLayoutHeartSVG)
export const CollageLayoutNotesIcon = createIcon(CollageLayoutNotesSVG)
export const CollageLayoutCenteredIcon = createIcon(CollageLayoutCenteredSVG)
export const CameraButtonIcon = createIcon(CameraButtonSVG)
export const CameraPlusIcon = createIcon(CameraPlusSVG)
export const TrashIcon = createIcon(TrashSVG)
export const VSignIcon = createIcon(VSignSVG)
export const SuitcaseIcon = createIcon(SuitcaseSVG)
export const InfoIcon = createIcon(I)
export const PhotosIcon = createIcon(PhotosSVG)

// To add another icon: https://www.notion.so/mixtiles/Icons-Usage-Adding-New-Ones-Etc-e3c75af3ca1d48558ec99c4ead48b58a
