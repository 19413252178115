import { type Tile } from 'components/TilesProvider/TilesProvider.types'
import { CUSTOM_SIGNS_TYPE } from './consts'
import { type ExperimentManager } from 'services/ExperimentManager/ExperimentManager'

export function canReplaceCenterpiece({
  tile,
  experimentManager,
}: {
  tile: Tile
  experimentManager: ExperimentManager
}) {
  if (!tile) return false
  const customSignsEnabled = experimentManager.isEnabled('custom-signs')

  // If custom signs are enabled, we can only replace tiles that have the CUSTOM_SIGNS_TYPE
  // If custom signs are not enabled, we can only replace tiles that don't have the CUSTOM_SIGNS_TYPE
  return customSignsEnabled
    ? tile.customCenterpieceProps?.type === CUSTOM_SIGNS_TYPE
    : tile.customCenterpieceProps?.type !== CUSTOM_SIGNS_TYPE
}

export function isCustomSign(tile: {
  customCenterpieceProps?: {
    type: string
  }
}): boolean {
  return tile.customCenterpieceProps?.type === CUSTOM_SIGNS_TYPE
}
