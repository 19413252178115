import { type Dimensions } from '../components/PhotoBank/PhotoBank.types'
import { type Rect } from '../utils/cropUtils.types'
import { isProduction } from '../utils/utils'
import { isClient } from 'utils/runtimeUtils'

export const CLOUDINARY_BASE_URL =
  'https://res.cloudinary.com/mixtiles/image/upload'
export const CLOUDINARY_ART_BASE_URL =
  'https://res.cloudinary.com/mixtiles-art/image/private'

export const UPLOAD_TYPE = 'upload'

export const isCloudinaryUrl = (url: string) =>
  url.includes('res.cloudinary.com')

/* Cloudinary turns all empty space into _ and removes commas and hyphens - we need to match it so the url will work */
export const turnIntoCloudinaryUrl = (url: string) =>
  url.replace(/ /g, '_').replace(/,/g, '').replace(/'/g, '_')

export const getOptimizedUrl = (
  path: string,
  { width, height }: Partial<Dimensions> = {},
  baseUrl = CLOUDINARY_BASE_URL
) => {
  let url = `${baseUrl}/f_auto,q_auto`

  const ratio = isClient() ? window.devicePixelRatio : 0

  if (width) {
    url += `,w_${width * Math.ceil(ratio || 1)}`
  }

  if (height) {
    url += `,h_${height * Math.ceil(ratio || 1)}`
  }

  return `${url}/${path}`
}

export const optimizeCloudinaryUrl = (
  url: string,
  {
    width,
    height,
    fill,
  }: Partial<{
    width: number
    height: number
    fill: boolean
  }> = {},
  type: string = UPLOAD_TYPE
) => {
  const parts = url.split(`/${type}/`)
  let optimizedUrl = `${parts[0]}/${type}/f_auto,q_auto:good`

  if (width) {
    optimizedUrl += `,w_${width}`
  }

  if (height) {
    optimizedUrl += `,h_${height}`
  }

  if (fill) {
    optimizedUrl += ',c_fill'
  }

  return `${optimizedUrl}/${parts[1]}`
}

export const getOptimizedDimensions = ({ width, height }: Dimensions) => {
  const dpr = Math.ceil(window.devicePixelRatio || 1)
  return {
    width: width * dpr,
    height: height * dpr,
  }
}

export const transformImageUrl = (
  imageUrl: string,
  crop?: Rect,
  thumbSize: Dimensions | null = null,
  shouldOptimize: boolean = true
) => {
  const CLOUDINARY_TRANSFORM_URL = isProduction()
    ? 'https://res.cloudinary.com/mixtiles/image/fetch'
    : 'https://res.cloudinary.com/mixtiles-dev/image/fetch'

  let url = `${CLOUDINARY_TRANSFORM_URL}${
    shouldOptimize ? '/f_auto,q_auto:good' : ''
  }`

  if (crop) {
    url = `${url}/${getCropUrlPart(crop)}`
  }

  if (thumbSize) {
    url = `${url}/${getThumbnailUrlPart(thumbSize)}`
  }

  return `${url}/${imageUrl}`
}

export const getCropUrlPart = ({ x, y, width, height }: Rect) => {
  return `x_${Math.round(x)},y_${Math.round(y)},w_${Math.round(
    width
  )},h_${Math.round(height)},c_crop`
}

export const getThumbnailUrlPart = (thumbSize: Dimensions) => {
  return `c_thumb,w_${Math.round(thumbSize.width)},h_${Math.round(
    thumbSize.height
  )}`
}

export const getScaleUrlPart = ({ width, height }: Dimensions) => {
  const widthParam = width ? `,w_${width}` : ''
  const heightParam = height ? `,h_${height}` : ''
  return `c_fill${widthParam}${heightParam}`
}
