import { type StateCreator } from 'zustand/vanilla'
import { type Store } from '../store.types'
import {
  PRODUCT_TYPES,
  VOUCHER_PRODUCT_TYPES,
} from '../../services/ProductTypeState'
import {
  type CheckoutSummary,
  type CheckoutSummaryProduct,
} from '../../components/Pricing/types/checkout.types'
import { calculateCheckoutSummary } from '../../components/Pricing/Pricing.api'
import { countryManager } from '../../services/CountryManager'
import {
  formatAddress,
  formatPricingSummary,
  NEW_PRICING_SUPPORTED_PRODUCT_TYPES,
  PRICING_ERROR_CONTEXT,
} from '../../components/Pricing/PricingUtil'
import { type Address } from '../addressSlice/addressSlice.types'
import { CHECKOUT_OPTION_KEY } from '@mixtiles/web-backend-shared'
import { logger } from '../../services/logger'
import { analytics } from '../../services/Analytics/Analytics'
import { v4 as uuid } from 'uuid'
import { ORDER_TYPE_PRODUCT } from '../../utils/OrderType'
import isEqual from 'lodash/isEqual'
import { checkoutSummaryProductId } from './orderUtils'
import { type OrderSlice } from './orderSlice.types'

const CALCULATE_CHECKOUT_NUM_RETRIES = 2

export const createOrderSlice =
  (
    api: any,
    productType: PRODUCT_TYPES
  ): StateCreator<Store, [['zustand/persist', unknown]], [], OrderSlice> =>
  (set, get) => {
    async function onCalculateCheckoutSummaryFailedAttempt(
      e: any,
      currParams: any
    ) {
      const isInvalidCoupon =
        e?.response?.data?.message?.includes('Invalid coupon')
      if (isInvalidCoupon) {
        await get().fetchDiscounts(true)
        const promoCode = get().discountCoupon?.code || ''
        return { ...currParams, promocode: promoCode }
      } else {
        return currParams
      }
    }

    async function calculateOrderSummary({
      products,
      address,
      discountId,
      checkoutId,
      promocode,
      giftCodes,
      curExecutionId,
    }: {
      products: CheckoutSummaryProduct[]
      address: Address | null
      discountId: string | null
      checkoutId: string
      promocode: string
      giftCodes: string[]
      curExecutionId: string
    }) {
      return api.call(
        calculateCheckoutSummary({
          productType,
          pricingCountry: countryManager.getCountry(),
          variantsIds: get().pricingVariantsIds,
          products,
          address: address && formatAddress(address),
          checkoutId,
          discountId,
          promocode,
          giftCodes,
          // add requestTimestamp in order to maintain request order in backend as well
          purchasingMixtilesPlus: false,
          timestamp: Date.now(),
          curExecutionId,
        }),
        CALCULATE_CHECKOUT_NUM_RETRIES,
        onCalculateCheckoutSummaryFailedAttempt
      )
    }

    async function fetchPricingSummary(curExecutionId: string) {
      const pricingSummary = get().getPricingSummary()
      const currentCheckoutId = pricingSummary?.checkoutId
      const giftCodes =
        productType === PRODUCT_TYPES.GIFT_CARD
          ? []
          : get().giftCardCreditsCodes

      const newPricingSummaryResults = await calculateOrderSummary({
        products: get().products,
        address: get().currentAddress,
        discountId: get().discountId,
        checkoutId: currentCheckoutId,
        promocode: get().discountCoupon?.code || '',
        giftCodes,
        curExecutionId,
      })

      return newPricingSummaryResults.checkoutResults?.map(
        (newPricingSummary: any) => {
          if (newPricingSummary.giftsBalances) {
            get().removeInvalidGiftCards(newPricingSummary.giftsBalances)
          }

          return formatPricingSummary({
            orderPriceSummary: newPricingSummary,
            discountId: get().discountId,
            orderType: ORDER_TYPE_PRODUCT,
            currency: get().pricingData.currency,
            checkoutId: newPricingSummaryResults.checkoutId,
            promoCode: get().discountCoupon?.code || '',
            productType,
            originalProducts: get().products,
          })
        }
      )
    }

    async function updatePricingSummaryOptions() {
      set(() => ({ isLoadingPricingSummary: true }))
      if (get().discounts.isLoading || get().pricingData.isLoading) {
        return
      }

      // Save current execution ID in order to update only the latest pricing response
      const curExecutionId = uuid()
      set(() => ({ lastExecutionUID: curExecutionId }))
      try {
        logger.info('Fetching pricingSummary options', {
          curExecutionId,
          products: get().products,
          discountId: get().discountId,
        })
        const pricingSummaryOptions = await fetchPricingSummary(curExecutionId)
        if (pricingSummaryOptions.length > 0) {
          analytics.setUserProperties({
            'Server Currency': pricingSummaryOptions[0].currency,
          })
        }
        if (curExecutionId !== get().lastExecutionUID) {
          logger.info('Skipping outdated pricingSummary options', {
            curExecutionId,
            lastExecutionUID: get().lastExecutionUID,
          })
          return
        }
        set(() => ({
          pricingSummaryError: null,
          pricingSummaryOptions,
          isLoadingPricingSummary: false,
        }))
      } catch (e: any) {
        if (curExecutionId !== get().lastExecutionUID) {
          return
        }
        set(() => ({ pricingSummaryOptions: [], pricingSummaryError: e }))
        logger.error('Failing to fetch pricingSummary options', e, {
          products: get().products,
          discountId: get().discountId,
          addressState: get().currentAddress,
          tags: { context: PRICING_ERROR_CONTEXT },
        })
      }
    }

    return {
      products: [],
      // don't save to storage
      previousProducts: [],
      previousDiscountID: null,
      lastExecutionUID: '',

      pricingSummaryError: null,
      isLoadingPricingSummary: true,
      pricingSummaryOptions: [],
      getPricingSummary: () => {
        const pricingKey = get().getPricingKey()
        return (
          get().pricingSummaryOptions.find(
            (pricingSummary: CheckoutSummary) =>
              pricingSummary.key === pricingKey
          ) || {}
        )
      },
      getPricingKey: () => {
        return VOUCHER_PRODUCT_TYPES.includes(productType)
          ? CHECKOUT_OPTION_KEY.GIFT_CARD
          : (get().selectedShippingOptionName as CHECKOUT_OPTION_KEY)
      },
      updatePricingSummaryIfNeeded: (params?: { force?: boolean }) => {
        const { force } = params || {}
        if (!NEW_PRICING_SUPPORTED_PRODUCT_TYPES.includes(productType)) {
          return
        }
        if (!get().products || get().products.length === 0) {
          set(() => ({
            previousProducts: [],
            previousDiscountID: get().discountId,
          }))

          return
        }

        const currentProducts = get()
          .products.map(checkoutSummaryProductId)
          .sort()
        // Checkout data needs to be recalculated only if the basic upload information is different from the last time
        // i.e - changes to crop shouldn't trigger a recalculation of the delivery promise
        if (
          force ||
          !isEqual(get().previousProducts, currentProducts) ||
          get().discountId !== get().previousDiscountID
        ) {
          set(() => ({ pricingSummaryError: null }))
          updatePricingSummaryOptions()
        }
        set(() => ({
          previousProducts: currentProducts,
          previousDiscountID: get().discountId,
        }))
      },
      resetPricingSummary: () => {
        set(() => ({
          pricingSummaryOptions: [],
          previousProducts: [],
          previousDiscountID: null,
          isLoadingPricingSummary: true,
        }))
      },
    }
  }
