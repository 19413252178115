import {
  ART_COLLECTION_ENTRYPOINT_URL,
  DISCOVERY_URL,
  JUMBLE_URL_PREFIX,
  PHOTO_BOOK_URL,
  PHOTO_STYLER_URL,
} from '../config/config'
import * as queryString from 'query-string'
import { ART_ROUTES, ART_SCREENS } from '../Art/config/art-consts'
import {
  getSearchParams,
  omitLocationParam,
  parseLocation,
  getQueryString as getQueryStringUtil,
} from '../utils/location'
import { isQuizFirst } from './loginFlows'
import { FORCE_QUIZ_QUERY_PARAM } from './queryParam.consts'
import { PRODUCT_TYPES } from './ProductTypeState'
import { remixNavigationManager } from 'services/remixNavigationManager'
import { isClient } from 'utils/runtimeUtils'
import storageManager from './StorageManager'

const NEXT_PAGE_ON_FINISH_ONBOARDING = 'nextOnFinishOnboarding'

class NavigationManager {
  private historyLengthOnAppLoad: number

  private _nextOnFinishOnboarding: string | null

  constructor() {
    this.historyLengthOnAppLoad = isClient()
      ? this._getCurrentHistoryLength()
      : 0
    this._nextOnFinishOnboarding = storageManager.get(
      NEXT_PAGE_ON_FINISH_ONBOARDING
    )
  }

  private _getCurrentHistoryLength(): number {
    return window.history?.length ?? 0
  }

  private _isFirstAppPage(): boolean {
    return (
      this.historyLengthOnAppLoad !== 0 &&
      this.historyLengthOnAppLoad === this._getCurrentHistoryLength()
    )
  }

  getOnFinishOnboardingUrl(): string | null {
    return this._nextOnFinishOnboarding
  }

  isPreviousPageAnExternalLandingPage(): boolean {
    return (
      this._isFirstAppPage() &&
      window.KEYS?.authorizedLandingPages?.some((proxyRoute: string) =>
        document.referrer.includes(proxyRoute)
      )
    )
  }

  // TODO: all these functions should receive their params in an object
  goToPage(
    location: string,
    state: Record<string, any> = {},
    keepUrlParams: boolean = false,
    keepHistory: boolean = true
  ): void {
    const locationObject = parseLocation(location, keepUrlParams)

    remixNavigationManager.navigate(locationObject, {
      state,
      replace: !keepHistory,
    })
  }

  goToHomePage(productType: string): void {
    if (productType === PRODUCT_TYPES.ART) {
      this.goToMixtilesArt()
    } else {
      this.goToPage('/')
    }
  }

  replace(location: string): void {
    remixNavigationManager.navigate(parseLocation(location), {
      replace: true,
    })
  }

  /*
  Only replaces the given url params, without deleting any param or changing URL.
  Usually you should prefer using 'replace' method instead, that keeps only the mandatory params.
   */
  replaceParams(
    query: Record<string, any>,
    keepAllParams: boolean = true
  ): void {
    const location = window.location
    remixNavigationManager.navigate(
      {
        pathname: location.pathname,
        search: this.getQueryString(query, keepAllParams),
        hash: location.hash,
      },
      { replace: true }
    )
  }

  removeParams(params: string[]): void {
    const location = window.location
    const allParams = new URLSearchParams(
      queryString.parse(location.search) as Record<string, any>
    )
    params.forEach((p) => {
      allParams.delete(p)
    })
    remixNavigationManager.navigate(
      {
        pathname: location.pathname,
        search: allParams.toString(),
      },
      { replace: true }
    )
  }

  removeParam(param: string): void {
    this.removeParams([param])
  }

  getQueryString(query: Record<string, any>, keepAllParams: boolean): string {
    const params = getSearchParams(query, keepAllParams)
    return getQueryStringUtil(params) || ''
  }

  setNextOnFinishOnboarding(returnToLocation: Partial<Location>): void {
    const locationWithoutForceParam: Location = omitLocationParam(
      returnToLocation,
      FORCE_QUIZ_QUERY_PARAM
    )
    const nextOnFinishOnboarding = `${locationWithoutForceParam.pathname}${
      locationWithoutForceParam.search
        ? `?${locationWithoutForceParam.search}`
        : ''
    }`
    storageManager.set(NEXT_PAGE_ON_FINISH_ONBOARDING, nextOnFinishOnboarding)
    this._nextOnFinishOnboarding = nextOnFinishOnboarding
  }

  goNextOnFinishOnboarding(
    redirectTo?: string,
    state: Record<string, any> = {}
  ): void {
    const utmSource = new URLSearchParams(
      remixNavigationManager.getLocation().search
    ).get('utm_source')

    const redirection = redirectTo || this._nextOnFinishOnboarding

    if (redirection) {
      remixNavigationManager.navigate(redirection, {
        state: {
          source: 'Onboarding',
          ...state,
        },
      })
    } else if (isQuizFirst(utmSource)) {
      this.goToPage('/')
    } else {
      this.goToPhotoPicking()
    }
  }

  goToPhotoPicking(keepUrlParams: boolean = false): void {
    return this.goToPage(PHOTO_STYLER_URL, {}, keepUrlParams)
  }

  goToMixtilesArt(arrivedFrom?: string, newTab: boolean = false): void {
    /* We need to refresh to page when going to art, since we need to reload the facebook pixel (which is different for art) */

    let destination = ART_ROUTES[ART_SCREENS.HOME]

    const query = arrivedFrom ? `?arrivedFrom=${arrivedFrom}` : ''
    destination += query
    if (newTab) {
      window.open(destination, '_blank')
    } else {
      window.location.href = destination
    }
  }

  getOnboardingFlow(): string | undefined {
    if (this._nextOnFinishOnboarding) {
      const pathname = new URL(
        this._nextOnFinishOnboarding,
        window.location.origin
      ).pathname

      if (['/', PHOTO_STYLER_URL].includes(pathname)) {
        return 'Main'
      } else if (pathname.startsWith(ART_COLLECTION_ENTRYPOINT_URL)) {
        return 'Art'
      } else if (pathname.startsWith(PHOTO_BOOK_URL)) {
        return 'Photo Books'
      } else if (pathname === DISCOVERY_URL) {
        return 'Gallery Walls'
      } else if (pathname.startsWith(JUMBLE_URL_PREFIX)) {
        return 'Jumble'
      }
    }
    return undefined
  }
}

export const navigationManager = new NavigationManager()
