import { styled } from 'styled-components'
import { Link } from '@remix-run/react'
import { onMediumScreen } from 'mixtiles-web-common'
import Button from 'mixtiles-web-common/ui/Button'
import { withStyles } from '@mui/styles'
import { Drawer as MuiDrawer } from '@mui/material'
import { ThinBackArrowIcon } from '../../icons'
import { ReactComponent as GoogleIcon } from '../../images/icons/googleSmall.svg'
import { ReactComponent as AppleIcon } from '../CheckoutSheet/icons/applePay.svg'
import { ReactComponent as LetterIcon } from '../../icons/assets/Letter.svg'

export const Letter = styled(LetterIcon)`
  width: 22px;
  height: 22px;
`

export const Google = styled(GoogleIcon)`
  width: 22px;
  height: 22px;
`

export const Apple = styled(AppleIcon)`
  width: 22px;
  height: 22px;
`

interface MainContainerProps {
  $height?: number
}

export const MainContainer = styled.div<MainContainerProps>`
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
  transition: height 0.2s;
`

export const ContentContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
`

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px 0 12px;
  box-sizing: border-box;
  z-index: 1;

  .MixtilesMenu.dark .menu-button-wrapper .menu-button {
    position: relative;
  }
`

export const BackButton = styled(ThinBackArrowIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondaryDark};
`

export const ViewContainer = styled.div`
  ${onMediumScreen()} {
    width: 387px;
    margin: auto;
  }
`

export const BottomDrawer = withStyles({
  paper: {
    borderRadius: '20px 20px 0 0',
  },
})(MuiDrawer)

export const Content = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface TitleProps {
  $isMixtilesPlusFlow?: boolean
}

export const Title = styled.div<TitleProps>`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  max-width: ${({ $isMixtilesPlusFlow }) =>
    $isMixtilesPlusFlow ? '250px' : 'unset'};
`

export const CodeTitle = styled.div`
  margin-top: 25px;
  ${onMediumScreen()} {
    margin-top: 0;
  }
  text-align: center;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
`

export const CodeSubtitle = styled.div`
  margin: 6px auto 0 auto;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.secondaryDark};
  max-width: 257px;
`

export const EmailForm = styled.form`
  margin: 0 auto 0 auto;
  ${onMediumScreen()} {
    margin: 24px auto 0 auto;
  }
`

export const CodeForm = styled.form`
  margin: 24px auto 0 auto;
`

export const LoginInput = styled.input`
  display: block;
  position: relative;
  width: 288px;
  margin: 0 auto 0 auto;
  border-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.colors.grayAccent}`};
  padding: 0 19px 0 19px;

  height: 46px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};

  /* Remove input type number arrows */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.grayDark};
  }

  &:focus {
    border: ${({ theme }) => `2px solid ${theme.colors.black}`};
    outline: none;
  }
`

export const Ors = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 25px auto 0 auto;
`

export const OrLineLeft = styled.div`
  margin-right: 25px;
  margin-left: auto;
  width: 134px;
  margin-top: 2%;
  height: 1px;
  background: ${({ theme }) => theme.colors.grayMedium};
`

export const Or = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.secondaryDark};
`

export const OrLineRight = styled.div`
  margin-left: 25px;
  margin-right: auto;
  width: 134px;
  margin-top: 2%;
  height: 1px;
  background: ${({ theme }) => theme.colors.grayMedium};
`

export const Continue = styled(Button)`
  && {
    position: relative;
    cursor: pointer;
    width: 330px;
    margin: 10px auto 0 auto;
    height: 50px;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 8px;
    border: none;
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
  }
`

export const SocialButtons = styled.div`
  position: relative;
  margin: 28px auto 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const SocialButton = styled.button`
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto 0 auto;
  width: 315px;
  height: 52px;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.grayMedium}`};
  color: ${({ theme }) => theme.colors.black};

  transition: background 0.2s;
  &:hover {
    background: ${({ theme }) => theme.colors.grayBackgroundWhite};
  }
`

export const SocialButtonContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const IconContainer = styled.div``

export const SocialButtonText = styled.div`
  font-weight: 600;
  font-size: 16px;
`

export const Terms = styled.div`
  position: relative;
  width: 242px;
  height: 34px;
  text-align: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16.9px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grayDark};
`

export const LoginLink = styled(Link)`
  text-decoration: underline;
  color: unset;
`

export const ResendCodeButton = styled.div`
  margin: 30px 0 52px 0;
  ${onMediumScreen()} {
    margin: 30px 0 36px 0;
  }
  text-align: center;
  text-decoration-line: underline;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.grayDark};
  cursor: pointer;
`

export const Skip = styled.span`
  color: ${({ theme }) => theme.colors.grayDark};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  cursor: pointer;
`
