// Get the productType for the pricing API calls (for the pricing-items and discounts).
// For example for gift card productType, we want the classic pricing-items and
// discounts - because the gift card applied on the classic product
import { PRODUCT_TYPES } from '../../services/ProductTypeState'
import { sendPricingErrorAnalytics } from '../../services/Analytics/pricingAnalytics'
import { logger } from '../../services/logger'
import {
  NEW_PRICING_SUPPORTED_PRODUCT_TYPES,
  PRICING_ERROR_CONTEXT,
} from '../../components/Pricing/PricingUtil'
import { type PricingItem } from '../../components/Pricing/types/pricingItem.types'
import {
  formatPrice,
  type FRAME_COLORS,
  hashTilePricingProduct,
  type MATERIAL_TYPES,
  type TILE_SIZES,
} from '@mixtiles/web-backend-shared'
import { countryManager } from '../../services/CountryManager'
import { type WallFramePresetGroup } from '../../components/WallDesigner/WallDesignerProvider/GalleryWallsV2Presets'
import {
  type PriceDifferenceMap,
  type PricingData,
} from '../../components/Pricing/types/PricingProvider.types'

export const getPricingProductType = (productType: PRODUCT_TYPES) => {
  if (productType === PRODUCT_TYPES.GIFT_CARD) {
    return PRODUCT_TYPES.CLASSIC
  } else {
    return productType
  }
}
export const reportPricingError = (
  message: string,
  error: any,
  pricingCountry: string,
  pricingVariantsIds: any,
  productType: PRODUCT_TYPES
) => {
  sendPricingErrorAnalytics(error)
  logger.error(message, error, {
    tags: { context: PRICING_ERROR_CONTEXT },
    pricingCountry,
    pricingVariantsIds,
    productType,
  })
}

export const shouldUseAPI = (productType: PRODUCT_TYPES) =>
  NEW_PRICING_SUPPORTED_PRODUCT_TYPES.includes(productType)

export function formatPricingItems(
  pricingItems: PricingItem[]
): Record<string, PricingItem> {
  return Object.fromEntries(
    pricingItems.map((item) => [
      hashTilePricingProduct(item.pricingProduct),
      {
        ...item,
      },
    ])
  )
}

export const getFramePriceDiff = (
  combination:
    | WallFramePresetGroup
    | { material: MATERIAL_TYPES; frameColor: FRAME_COLORS },
  pricingCountry: string,
  shouldRound: boolean,
  priceDifferenceMap: PriceDifferenceMap,
  pricingData: PricingData
) => {
  if (!priceDifferenceMap) {
    return '0'
  }
  const comb = 'configs' in combination ? combination.configs[0] : combination
  const material = (
    'materialType' in comb ? comb.materialType : comb.material
  ) as MATERIAL_TYPES

  return formatPrice({
    price: priceDifferenceMap[material][comb.frameColor],
    country: pricingCountry,
    shouldRound,
    currency: pricingData.currency,
    roundingPrecision: false,
  })
}

export const getPresetPriceDiff = (
  combination:
    | WallFramePresetGroup
    | { material: MATERIAL_TYPES; frameColor: FRAME_COLORS },
  priceDifferenceMap: PriceDifferenceMap,
  pricingData: PricingData,
  frameSizes: TILE_SIZES[]
) => {
  if (!priceDifferenceMap) {
    return '0'
  }

  if (!('configs' in combination) || combination.configs.length === 0) {
    return '0'
  }

  let totalValue = 0
  const numFrames = frameSizes.length
  const configs = combination.configs

  // Calculate how many frames each config will be applied to
  const baseCount = Math.floor(numFrames / configs.length)
  const remainder = numFrames % configs.length

  configs.forEach((config: any, index: number) => {
    // Add extra frame to first 'remainder' configs
    const framesForThisConfig = baseCount + (index < remainder ? 1 : 0)
    const priceDiff = getFramePriceDiff(
      { material: config.materialType, frameColor: config.frameColor },
      countryManager.getPricingCountry(),
      false,
      priceDifferenceMap,
      pricingData
    )
    const value = parseFloat(priceDiff.replace(/[^0-9.-]+/g, ''))
    totalValue += value * framesForThisConfig
  })

  if (totalValue === 0) {
    return '0'
  }

  const formattedPrice = getFramePriceDiff(
    { material: configs[0].materialType, frameColor: configs[0].frameColor },
    countryManager.getPricingCountry(),
    false,
    priceDifferenceMap,
    pricingData
  )
  const numericPart = formattedPrice.replace(/[^0-9.-]+/g, '')

  return formattedPrice.replace(numericPart, totalValue.toString())
}
