import pick from 'lodash/pick'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import StorageManager from '../../services/StorageManager'
import { DEFAULT_STORAGE_KEY } from '../../components/TilesProvider/TilesProvider.consts'
import { isCenterpiece, isCollage } from '@mixtiles/web-backend-shared'
import { isCustomSign } from '../../components/CenterpieceDesigner/helpers'

const COMMON_KEYS = [
  'id',
  'cropParams',
  'style',
  'tileSize',
  'uploadedAt',
  'metadata',
  'materialType',
  'customStyle',
  'originalUrl',
  'tileContent',
  'currentAspectRatio',
  'width',
  'height',
]
const SAVE_TILE_KEYS = [...COMMON_KEYS, 'useLowQualityAnyway', 'uid', 'artwork']
const SAVE_COLLAGE_KEYS = [...COMMON_KEYS, 'collage']
const SAVE_CENTERPIECE_KEYS = [
  ...COMMON_KEYS,
  'highResUrl',
  'lowResUrl',
  'centerpieceTitle',
  'customCenterpieceProps',
  'isCustomized',
]
const SAVE_CUSTOM_SIGN_KEYS = [...SAVE_CENTERPIECE_KEYS, 'uid']

export const saveTiles = (
  tiles,
  storageKey = DEFAULT_STORAGE_KEY,
  forcePhotoBank = true
) => {
  const tilesObj = tiles
    .map((tile) => {
      if (isCollage(tile)) {
        const collagePhotos = tile.collage?.photos
        if (!collagePhotos || !Object.keys(collagePhotos).length) {
          return null
        }

        return pick(tile, SAVE_COLLAGE_KEYS)
      }

      if (isCustomSign(tile)) {
        return pick(tile, SAVE_CUSTOM_SIGN_KEYS)
      }

      if (isCenterpiece(tile)) {
        return pick(tile, SAVE_CENTERPIECE_KEYS)
      }

      if (!tile.url) {
        return null
      }

      if (tile.uid || !forcePhotoBank) {
        return pick(tile, SAVE_TILE_KEYS)
      }

      return null
    })
    .filter(Boolean)

  StorageManager.set(storageKey, uniqBy(tilesObj, 'id'))
}

export const getTiles = (storageKey = DEFAULT_STORAGE_KEY) => {
  return sortBy(StorageManager.get(storageKey) || [], 'uploadedAt')
}
