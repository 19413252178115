import { getOptimizedUrl } from 'services/CloudinaryService'
import YoungSerif from 'media/fonts/Young-Serif.woff2'
import Poppins from 'media/fonts/Poppins.woff2'
import Hendangan from 'media/fonts/Hendangan.woff'

export const CUSTOM_SIGNS_TYPE = 'custom-signs'

export type CenterpieceStyle = 'style1' | 'style2' | 'style3'
export type CenterpieceStyleConfig = {
  bg: {
    mobile: string
    full: string
  }
  font: {
    family: string
    src: string
    weight: string
    type: string
  }
}

const mobileSize = {
  width: 670,
  height: 230,
}
const basePath = 'v1737384863/centerpieces/v2'

export const centerpieceConfig: Record<
  CenterpieceStyle,
  CenterpieceStyleConfig
> = {
  style1: {
    font: {
      family: 'Young Serif',
      // Weight has to match the wght parameter in the src
      weight: '400',
      src: YoungSerif,
      type: 'font/woff2',
    },
    bg: {
      mobile: getOptimizedUrl(`${basePath}/bg-1-full.png`, mobileSize),
      full: getOptimizedUrl(`${basePath}/bg-1-full.png`),
    },
  },
  style2: {
    font: {
      family: 'Poppins',
      weight: '500',
      src: Poppins,
      type: 'font/woff2',
    },
    bg: {
      mobile: getOptimizedUrl(`${basePath}/bg-2-m.png`, mobileSize),
      full: getOptimizedUrl(`${basePath}/bg-2-full.png`),
    },
  },
  style3: {
    font: {
      family: 'Hendangan',
      weight: '400',
      src: Hendangan,
      type: 'font/woff',
    },
    bg: {
      mobile: getOptimizedUrl(`${basePath}/bg-3-m.png`, mobileSize),
      full: getOptimizedUrl(`${basePath}/bg-3-full.png`),
    },
  },
}
