import {
  MATTING,
  PHOTO_ORIENTATIONS,
  tileSizeToOrientation,
  DEEP_MATTING_SMALLER_EDGE_PROPORTION,
  TILE_SIZES,
} from '@mixtiles/web-backend-shared'

const HEART_HEIGHT = 526
const HEART_WIDTH = 605

const OVAL_NARROW_SIDE = 565
const OVAL_WIDE_SIDE = 856

const SHALLOW_MATTING_PADDING = 0.1
const MEDIUM_MATTING_PADDING = 0.2
const DEEP_MATTING_SQUARE_PADDING = 0.28
const DEEP_MATTING_RECTANGLE_PADDING = 0.25

function getEqualPaddingRect({ width, height, padding }) {
  const mattingPixels = Math.min(width, height) * padding
  return {
    x: mattingPixels,
    y: mattingPixels,
    width: width - 2 * mattingPixels,
    height: height - 2 * mattingPixels,
  }
}

function getDeepMattingRect({ width, height, orientation }) {
  if (orientation === PHOTO_ORIENTATIONS.SQUARE) {
    return getEqualPaddingRect({
      width,
      height,
      padding: DEEP_MATTING_SQUARE_PADDING,
    })
  } else {
    const deepBorderPercentage = DEEP_MATTING_RECTANGLE_PADDING
    const smallEdgeBorderPercentage =
      deepBorderPercentage * DEEP_MATTING_SMALLER_EDGE_PROPORTION

    let verticalBorder
    let horizontalBorder

    if (orientation === PHOTO_ORIENTATIONS.LANDSCAPE) {
      verticalBorder = width * deepBorderPercentage
      horizontalBorder = width * smallEdgeBorderPercentage
    } else {
      horizontalBorder = height * deepBorderPercentage
      verticalBorder = height * smallEdgeBorderPercentage
    }

    return {
      x: verticalBorder,
      y: horizontalBorder,
      width: width - verticalBorder * 2,
      height: height - horizontalBorder * 2,
    }
  }
}

function getRoundMattingRect({ width, height, orientation }) {
  if (orientation === PHOTO_ORIENTATIONS.SQUARE) {
    return getEqualPaddingRect({ width, height, padding: 0.17 })
  } else {
    let verticalBorder
    let horizontalBorder
    let imageHeight
    let imageWidth

    if (orientation === PHOTO_ORIENTATIONS.LANDSCAPE) {
      verticalBorder = height * 0.17
      imageHeight = height - verticalBorder * 2
      imageWidth = (imageHeight * OVAL_WIDE_SIDE) / OVAL_NARROW_SIDE
      horizontalBorder = (width - imageWidth) / 2
    } else {
      horizontalBorder = width * 0.17
      imageWidth = width - horizontalBorder * 2
      imageHeight = (imageWidth * OVAL_WIDE_SIDE) / OVAL_NARROW_SIDE
      verticalBorder = (height - imageHeight) / 2
    }

    return {
      x: verticalBorder,
      y: horizontalBorder,
      width: imageWidth,
      height: imageHeight,
    }
  }
}

function getHeartMattingRect({ width, height, orientation }) {
  let verticalBorder
  let horizontalBorder
  let imageWidth
  let imageHeight

  if (orientation === PHOTO_ORIENTATIONS.SQUARE) {
    horizontalBorder = width * 0.17
    imageWidth = width - horizontalBorder * 2
    imageHeight = (imageWidth * HEART_HEIGHT) / HEART_WIDTH
    verticalBorder = (height - imageHeight) / 2
  } else {
    if (orientation === PHOTO_ORIENTATIONS.LANDSCAPE) {
      verticalBorder = height * 0.15
      imageHeight = height - verticalBorder * 2
      imageWidth = (imageHeight * HEART_WIDTH) / HEART_HEIGHT
      horizontalBorder = (width - imageWidth) / 2
    } else {
      horizontalBorder = width * 0.15
      imageWidth = width - horizontalBorder * 2
      imageHeight = (imageWidth * HEART_HEIGHT) / HEART_WIDTH
      verticalBorder = (height - imageHeight) / 2
    }
  }
  return {
    x: horizontalBorder,
    y: verticalBorder,
    width: imageWidth,
    height: imageHeight,
  }
}

function getTopMattingRect({ width, orientation }) {
  if (orientation !== PHOTO_ORIENTATIONS.PORTRAIT) {
    return null
  }

  const mattingPixels = width * 0.15
  const newWidth = width - 2 * mattingPixels
  return {
    x: mattingPixels,
    y: mattingPixels,
    width: newWidth,
    height: 0.75 * newWidth,
  }
}

/**
 * Calculates the matting rectangle, the part of the frame contents in which the actual photo appears in.
 * @param width frame width
 * @param height frame height
 * @param {MATTING} mattingType
 * @param {TILE_SIZES} tileSize
 * @return {{x: number, width: number, y: number, height}|{x: number, width, y: number, height}|{x: *, width, y: *, height}}
 */
export function getMattingRect({ width, height, mattingType, tileSize }) {
  const orientation = tileSizeToOrientation(tileSize)
  const is22X44 = [
    TILE_SIZES.RECTANGLE_22X44,
    TILE_SIZES.RECTANGLE_44X22,
  ].includes(tileSize)

  switch (mattingType) {
    case MATTING.NONE:
      return getEqualPaddingRect({ width, height, padding: 0 })
    case MATTING.SHALLOW:
      return getEqualPaddingRect({
        width,
        height,
        padding: SHALLOW_MATTING_PADDING,
      })
    case MATTING.MEDIUM:
      return getEqualPaddingRect({
        width,
        height,
        padding: MEDIUM_MATTING_PADDING,
      })
    case MATTING.DEEP:
      return is22X44
        ? getEqualPaddingRect({
            width,
            height,
            padding: DEEP_MATTING_SQUARE_PADDING,
          })
        : getDeepMattingRect({ width, height, orientation })
    case MATTING.ROUND:
      return getRoundMattingRect({ width, height, orientation })
    case MATTING.HEART:
      return getHeartMattingRect({ width, height, orientation })
    case MATTING.TOP:
      return getTopMattingRect({ width, orientation })
    default:
      return getEqualPaddingRect({ width, height, padding: 0 })
  }
}

/**
 * Calculates the original frame dimensions from a matting rect and matting type - find the reverse dimensions of getMattingRect
 * @param {Rect} mattingRect - The matting rectangle
 * @param {MATTING} mattingType - Type of matting that was applied
 * @param {TILE_SIZES} tileSize - Size of the tile
 * @returns {{width: number, height: number}} Original frame dimensions
 */
export function getOriginalFrameDimensions({
  mattingRect,
  mattingType,
  tileSize,
}) {
  let horizontalBorderPercentage
  let verticalBorderPercentage

  switch (mattingType) {
    case MATTING.NONE:
      horizontalBorderPercentage = 0
      verticalBorderPercentage = 0
      break
    case MATTING.SHALLOW:
      horizontalBorderPercentage = SHALLOW_MATTING_PADDING
      verticalBorderPercentage = SHALLOW_MATTING_PADDING
      break
    case MATTING.MEDIUM:
      horizontalBorderPercentage = MEDIUM_MATTING_PADDING
      verticalBorderPercentage = MEDIUM_MATTING_PADDING
      break
    case MATTING.DEEP:
      const orientation = tileSizeToOrientation(tileSize)
      const is22X44 = [
        TILE_SIZES.RECTANGLE_22X44,
        TILE_SIZES.RECTANGLE_44X22,
      ].includes(tileSize)
      if (is22X44) {
        horizontalBorderPercentage = DEEP_MATTING_SQUARE_PADDING
        verticalBorderPercentage = DEEP_MATTING_SQUARE_PADDING
        break
      }
      // For deep matting, we need to consider orientation
      const deepBorderPercentage = DEEP_MATTING_RECTANGLE_PADDING
      const smallEdgeBorderPercentage =
        deepBorderPercentage * DEEP_MATTING_SMALLER_EDGE_PROPORTION

      if (orientation === PHOTO_ORIENTATIONS.LANDSCAPE) {
        horizontalBorderPercentage = deepBorderPercentage
        verticalBorderPercentage = smallEdgeBorderPercentage
        break
      } else if (orientation === PHOTO_ORIENTATIONS.PORTRAIT) {
        horizontalBorderPercentage = smallEdgeBorderPercentage
        verticalBorderPercentage = deepBorderPercentage
        break
      } else {
        horizontalBorderPercentage = DEEP_MATTING_SQUARE_PADDING
        verticalBorderPercentage = DEEP_MATTING_SQUARE_PADDING
        break
      }
    default:
      // For special matting types (ROUND, HEART, TOP), we can't reliably calculate original dimensions
      return null
  }

  return {
    width: mattingRect.width / (1 - 2 * horizontalBorderPercentage),
    height: mattingRect.height / (1 - 2 * verticalBorderPercentage),
  }
}
